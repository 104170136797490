:root {
    /* prefixed to avoid overwriting Bootstrap vars */
    --theme-primary: #007bff;
    --theme-blue: #006adb;
    --theme-danger: #d80f0f;
    --theme-dark: #787878;
    --theme-bg: #f7f9fc;
    --theme-border-color: #151515;
}

/* top level styles */

html,
body,
.page-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    background: var(--theme-bg);
}

.page-wrapper {
    display: flex;
    justify-content: center;
}

main {
    width: 490px;
    margin-top: 10vh;
}

/* generic elements */

* {
    box-sizing: border-box;
}

a {
    color: var(--theme-primary);
    text-decoration: none;
}

a:hover,
a:focus {
    outline: none;
    font-weight: bold;
}

.center {
    text-align: center;
}

/* brand image */

.brand {
    height: 100px;
    display: block;
    margin: 0 auto 30px auto;
}

/* form box elements */

.form-box {
    background: white;
    padding: 10px 40px;
    margin-bottom: 10px;
    border: 1px solid var(--theme-dark);
    box-shadow: 0 0 15px rgba(0, 106, 219, 0.1);
    border-radius: 4px;

    h1 {
        text-align: center;
        font-weight: normal;
    }

    .form-box-error-message {
        margin: 10px 0;
        display: inline-block;
        color: var(--theme-danger);
        opacity: 0;

        &.active {
            opacity: 1;
        }
    }

    p {
        line-height: 1.5em;
    }

    .form-box-bottom-links {
        display: block;
        text-align: center;
        font-size: 1em;
        margin-top: 20px;
    }

    .form-box-footer {
        margin: 50px 0 20px 0;
        text-align: center;
        font-size: 0.875em;
    }

    .form-box-footer a,
    a.bold-on-hover {
        display: inline-block;

        &::before {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}

/* form elements */

.form-box .field-wrapper {
    margin-top: 15px;

    .input-wrapper {
        position: relative;

        input {
            height: 50px;
            background: none;
            outline: none;
            padding: 10px;
            width: 100%;
            display: block;
            border: 1px solid var(--theme-dark);
            box-sizing: border-box;
            border-radius: 4px;

            &:focus {
                border: 2px solid var(--theme-border-color);
            }

            &:invalid,
            &.is-invalid {
                border: 1px solid var(--theme-danger);
            }
        }

        .show-pass-btn {
            position: absolute;
            top: 50%;
            right: 10px;
            background: none;
            border: none;
            transform: translateY(-50%);
            font-size: 14px;
            color: var(--theme-dark);
            cursor: pointer;
            outline: none;
        }
    }

    label {
        display: inline-block;
        margin-bottom: 5px;
        color: var(--theme-dark);
        font-size: 15px;
    }

    &:focus-within label {
        color: black;
    }
}

.submit-btn {
    background: var(--theme-blue);
    outline: none;
    border-radius: 4px;
    color: white;
    padding: 15px 20%;
    min-width: 50%;
    margin: 20px auto auto auto;
    display: block;
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid transparent;

    &:focus {
        border-color: var(--theme-border-color);
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.password-reset-confirm {
    margin-top: 5vh;

    ul li {
        line-height: 1.6em;
    }

    .help ul {
        margin: 0 0 2em 0;
    }

    .errorlist {
        color: var(--theme-danger);
    }
}

@media (max-width: 600px) {
    main {
        width: calc(100% - 4em);
    }
    .form-box {
        padding: 10px 2em;
    }
}

@media (max-width: 500px) {
    main {
        margin-top: 5vh;
        width: calc(100% - 2em);
    }
    .form-box {
        padding: 10px 1em;
    }
}
